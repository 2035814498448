import React, { useEffect, useState } from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const PhoneNumber = ({
  label,
  required,
  name,
  value,
  onChange,
  width,
  errors,
  touched,
  validate,
  disabled,
  initialCountry,
}) => {
  const [initialValue, setInitialValue] = useState(value);
  const [initialCode, setInitialCode] = useState(initialCountry || "ae");
  const inputStyle = {
    width: "100%",
    border: errors && touched ? "1px solid red" : "1px solid hsl(0, 0%, 80%)",
  };

  const handlePhoneChange = (phone) => {
    setInitialValue(phone);
    onChange(name, phone);
  };

  const handleValidate = (value) => {
    const isValid = isValidPhoneNumber(value);
    return isValid;
  };

  useEffect(() => {
    setInitialValue(value);
  }, [value]);

  useEffect(() => {
    setInitialCode(initialCountry);
  }, [initialCountry]);

  return (
    <div style={{ color: disabled ? "#1b224c3f" : "#1B224C" }}>
      {label ? (
        <label htmlFor={name}>
          {label}
          {required ? <span className='required'>*</span> : ""}
        </label>
      ) : (
        ""
      )}

      <PhoneInput
        country={'ae'}
        placeholder=''
        countryCodeEditable={true}
        value={initialValue}
        onChange={handlePhoneChange}
        autoComplete='off'
        required={required}
        inputStyle={inputStyle}
        // isValid={(value, country) => {
        //   return validate(value, country);
        // }}
        disabled={disabled ? true : false}
      />
    </div>
  );
};

export default PhoneNumber;
