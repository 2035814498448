import React from "react";
import SquareCheckBox from "../../Inputs/SquareCheckBox";
import SectionTitle from "../../Texts/SectionTitle";
import { useFormikContext } from "formik";
import EnrollmentAgreement from "../../Agreements/EnrollmentAgreement";
import HousingFees from "../../Agreements/HousingFees";
import Tuition from "../../Agreements/Tuition";

const Reservation = ({ handleClick, isView }) => {
  const formik = useFormikContext();
  return (
    <>
      {" "}
      <div className='form-subcontainers'>
        <SectionTitle title='RESERVATION AND ENROLLMENT AGREEMENT' />
        <EnrollmentAgreement />
        <Tuition />
        <HousingFees />
        <div className='d-flex'>
          <SquareCheckBox
            text='I hereby acknowledge the terms and conditions of the agreement listed above '
            name='TermAndConditionCheck'
            value={formik.values.TermAndConditionCheck}
            onChange={(checked) => {
              formik.setFieldValue("TermAndConditionCheck", checked);
            }}
            errors={formik.errors?.TermAndConditionCheck}
            touched={formik.errors?.TermAndConditionCheck}
            disabled={isView}
          />
        </div>{" "}
      </div>
    </>
  );
};

export default Reservation;
