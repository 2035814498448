import React from "react";
import "./AggreementTable.css";
const AgreementTable = () => {
  return (
    <div className='refund-table-container'>
      <table className='refund-table'>
        <thead>
          <tr>
            <th colSpan='2' style={{ color: "#3886bd" }}>
              Withdrawal/Dismissal from All Classes
            </th>

            <th colSpan='2' style={{ color: "#3886bd" }}>
              Withdrawal from Partial Classes
            </th>
          </tr>
          <tr>
            <th>Schedual</th>
            <th>Refund</th>
            <th>Schedual</th>
            <th>Refund</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>During Drop/Add*</td>
            <td>100%</td>
            <td>Until end of Drop/Add*</td>
            <td>100%</td>
          </tr>
          <tr>
            <td>Until the end of the second week**</td>
            <td>50%</td>
            <td>After Drop/Add</td>
            <td>0%</td>
          </tr>
          <tr>
            <td>Until the end of the third week</td>
            <td>25%</td>
          </tr>
          <tr>
            <td>After the third week</td>
            <td>0%</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default AgreementTable;
