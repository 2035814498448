import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
const TextEditor = ({
  name,
  value,
  onChange,
  errors,
  touched,
  label,
  disabled,
  required,
  count,
}) => {
  const [editorContent, setEditorContent] = useState(value || "");

  const handleEditorChange = (content) => {
    setEditorContent(content);
    onChange(name, content);
  };

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ size: [] }],
      [{ align: [] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
    ],
  };
  const countWords = (content) => {
    // Replace block-level elements or line breaks with spaces
    const normalizedContent = content
      ?.replace(/<\/?[^>]+(>|$)/g, " ") // Replace HTML tags with spaces
      .replace(/\s+/g, " ") // Normalize multiple spaces
      .trim(); // Trim leading and trailing spaces

    if (!normalizedContent) return 0;

    return normalizedContent.split(" ").length;
  };
  useEffect(() => {
    countWords(editorContent);
  }, [editorContent]);
  useEffect(() => {
    setEditorContent(value);
  }, [value]);
  return (
    <div
      className={`${
        errors && touched ? "textBox-container-error " : "textBox-container"
      }`}
      style={{ cursor: disabled ? "not-allowed" : "pointer" }}
    >
      <label htmlFor={label}>
        {label}
        {required && <span className='required'>*</span>}
        <span className='span-required'>{errors && touched && errors}</span>
        {count && (
          <span className='words-number'>
            Count : {countWords(editorContent)}
          </span>
        )}
      </label>
      <ReactQuill
        theme='snow'
        value={editorContent}
        onChange={handleEditorChange}
        modules={modules}
        readOnly={disabled}
      />
    </div>
  );
};

export default TextEditor;
